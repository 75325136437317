import { css } from "styled-components";

const variables = css`
  :root {
    // GENERAL
    --side-space: 25px;
    --side-space-md: 50px;
    --side-space-lg: 100px;
    --vert-space: 20px;

    --grey: #D9D9D9;
    --green: rgb(10, 160, 114);
    --light-green: rgba(10, 160, 114, 0.5);
    --red: rgb(255,15,15);
    --light-red: rgba(255,15,15, 0.5);
    --dark-teal: #046B9A;
    --teal: #096C9B;
    --light-teal: #00A1BC;
    --light-teal-box-shadow: 0 0 0 0.25rem rgba(0, 161, 188, 0.25);

    --fw-bold: 700;

    // NAV
    --nav-height: 104px;

    --nav-logo-width: 125px;
    --nav-logo-height: 60px;

    --nav-item-gap: 50px;

    // BANNER
    --banner-height: 459px;
    --banner-txt-width: 800px;
    --banner-bg-gradient: linear-gradient(89.91deg, #005C86 0.09%, #046B9A 25.26%, 
      rgba(4, 107, 154, 0.77) 57.11%,
      rgba(4, 107, 154, 0.13) 75.6%);

    // SECTIONS
    --section-gap: 20px;
    --section-gap-lg: 30px;

    // BRAND
    --brand-item-height: 50px;
    --brand-item-gap: 15px;

    --brand-plus-width: 25px;
    --brand-plus-height: 25px;

    // SOLUTION
    --solution-type-width: 100px;
    --solution-type-width-lg: 150px;
    --solution-type-caption-height: 40px;
    --solution-type-caption-height-lg: 80px;
    --solution-type-caption-height-xl: 100px;

    --solution-type-outer-gap: 10px;
    --solution-type-outer-gap-lg: 30px;
    --solution-type-inner-gap: 20px;

    --solution-type-icon-width: 70px;
    --solution-type-icon-height: 70px;
    --solution-type-icon-width-lg: 94px;
    --solution-type-icon-height-lg: 94px;

    // CONTACT
    --contact-input-height: 70px;
    --contact-input-height-lg: 200px;

    --contact-button-width: 150px;
    --contact-button-height: 50px;
    --contact-button-radius: 10px;

    // HEADER
    --foot-height: 330px;
    --foot-border-width: 2px;

    --foot-about-gap: 50px;
    --foot-about-txt-width: 370px;
    --foot-about-txt-gap: 20px;

    --foot-logo-width: 200px;
    --foot-logo-height: 96px;

    --foot-info-gap: 40px;
    --foot-info-list-gap: 20px;

    // FONT
    --fs-xl: 56px;
    --fs-lg: 40px;
    --fs-md: 20px;
    --fs-sm: 12px;
  }
`;

export default variables;