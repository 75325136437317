import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { sendForm } from "@emailjs/browser";

const StyledContact = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input, textarea {
    &:focus  {
      border-color: var(--light-teal);
      box-shadow: var(--light-teal-box-shadow);
    }
  }

  textarea {
    resize: none;
  }
`;

const StyledSubmit = styled.div`
  display: flex;
  justify-content: center;

  padding-top: 1rem;

  button {
    display: ${props => props.hasSubmit ? 'none' : 'inline-block'};

    width: var(--contact-button-width);
    height: var(--contact-button-height);

    background-color: var(--dark-teal);
    border-color: var(--dark-teal);
    border-radius: var(--contact-button-radius);

    font-weight: var(--fw-bold);

    &:hover {
      background-color: var(--light-teal);
      border-color: var(--light-teal);
    }

    &:focus {
      box-shadow: var(--light-teal-box-shadow);
    }
  }

  .alert {
    flex-grow: 1;

    background-color: 
      var(--${props => props.alertColor === 'g' ? 'light-green' : 'light-red'});
    border-color: 
      var(--${props => props.alertColor === 'g' ? 'green' : 'red'});
    border-width: 2px;
    color: white;

    margin: 0;
    padding: 0.5rem 1rem;
    text-align: center;
  }
`;

function Contact({ href, title, formInfo }) {
  const [userValues, setUserValues] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [hasSubmit, setHasSubmit] = useState(false);
  const [alertColor, setAlertColor] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const getInputHeight = (i) => {
    const cssVar = 'var(--contact-input-height';
    return `${cssVar + (i === formInfo.length - 1 ? '-lg' : '')})`;
  };

  const emailServiceConfig = (form) => {
    return ([
      'default_service',
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      form,
      process.env.REACT_APP_EMAIL_PUBLIC_KEY
    ]);
  };

  const handleChange = (e) => {
    setUserValues({...userValues, [e.target.name]: e.target.value});
  };

  const handleAlert = (time, msg, success) => {
    setAlertMessage(msg);
    setHasSubmit(true);
    success ? setAlertColor('g') : setAlertColor('r');
    // setTimeout(() => setHasSubmit(false), time);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      sendForm(...emailServiceConfig(e.target))
        .then((res) => { 
          handleAlert(2000, 'Message Sent!', true);
          console.log(`Success! Status: ${res.status}. ${res.text}`); 
        })
        .catch((err) => {
          handleAlert(2000, 'Oops. Please try again later.', false);
          console.log(`sendForm() Error! Status: ${err.status}. ${err.text}`);
        });
    } catch (err) {
      handleAlert(2000, 'Oops. Please try again later.', false);
      console.log('sendForm() Config Error!', err);
    }

    // handle form data being sent to server
    // const data = new FormData(e.target);
    // const contactInfo = Object.fromEntries(data.entries());
    // console.log('Contact Info: ', contactInfo);
  };

  return (
    <StyledContact>
      <h3 id={href} className="section-title">{title}</h3>
      <Card style={{ border: 'none' }}>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row xs={1}>
              {
                formInfo.map(([ label, input ], i) => {
                  return (
                    <Col key={i}>
                      <FormGroup>       
                        <Label for={`contact${label}`}>{label}</Label>
                        <Input
                          id={`contact${label}`}
                          name={label.toLowerCase()}
                          placeholder={input.placeholder}
                          type={input.type}
                          style={{ height: getInputHeight(i) }}
                          value={userValues[label]}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  );
                })
              }
            </Row>
            <StyledSubmit alertColor={alertColor} hasSubmit={hasSubmit}>
              <Button>Send</Button>
              <Alert isOpen={hasSubmit}>{alertMessage}</Alert>
            </StyledSubmit>
          </Form>
        </CardBody>
      </Card>
    </StyledContact>
  );
}

Contact.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
  formInfo: PropTypes.array
};

export default Contact;