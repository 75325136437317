import PropTypes from "prop-types";
import styled from "styled-components";
import Head from "./Head";
import Foot from "./Foot";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #content {
    flex-grow: 1;
  }
`;

function Layout({ children }) {

  return (
    <StyledContent>
      <Head />
      <div id="content">
        <main>
          {children}
        </main>
      </div>
      <Foot />
    </StyledContent>
  );
}

Layout.propTypes = {
  children: PropTypes.array
};

export default Layout;