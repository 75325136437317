import hubZone from "./brand/hubzone.png";
import plus from "./brand/plus.png";
import psLogo from "./brand/ps-logo.png";
import sba from "./brand/sba.png";
import softek from "./brand/softek.png";

import agileIcon from "./solution/agile-icon.png";
import cxuxIcon from "./solution/cx-ux-icon.png";
import cyberSecurityIcon from "./solution/cyber-security-icon.png";
import dataIcon from "./solution/data-icon.png";
import digComIcon from "./solution/dig-com-icon.png";
import itIcon from "./solution/it-icon.png";

const brandLeft = [psLogo, plus, softek];
const brandRight = [hubZone, sba];

const solutionIcons = [
  agileIcon,
  dataIcon,
  cxuxIcon,
  itIcon,
  cyberSecurityIcon,
  digComIcon
];

export { brandLeft, brandRight, solutionIcons };
export { default as digitalNextLogo } from "./digital-next-logo.png";
export { default as unsplash } from "./unsplash.jpg";