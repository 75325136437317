import styled from "styled-components";

import { solutionIcons } from "../images";
import { solutionCaptions } from "../config"; 

const StyledSolution = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  gap: var(--solution-type-outer-gap-lg);

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    gap: var(--solution-type-outer-gap);
  }

  @media (min-width: 1224px) { gap: var(--solution-type-outer-gap-lg); }

  padding: 1rem var(--side-space);

  .solution-type {
    width: var(--solution-type-width);
    @media (min-width: 1224px) { width: var(--solution-type-width-lg); }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--solution-type-inner-gap);

    &-icon {
      img {
        width: var(--solution-type-icon-width);
        height: var(--solution-type-icon-height);

        @media (min-width: 1224px) {
          width: var(--solution-type-icon-width-lg);
          height: var(--solution-type-icon-height-lg);
        }
      }
    }

    &-caption {
      height: var(--solution-type-caption-height);
      @media (min-width: 1024px) { height: var(--solution-type-caption-height-lg); }
      @media (min-width: 1224px) { height: var(--solution-type-caption-height-xl); }
      text-align: center;
    }
  }
`;

function Solution() {
  let solutionTypes = [];

  if (solutionIcons.length !== solutionCaptions.length) {
    throw new Error('<Solution /> Error! Icons != Captions');
  } else {
    solutionIcons.forEach((icon, i) => {
      solutionTypes.push({ icon, caption: solutionCaptions[i] });
    });
  }

  return (
    <StyledSolution>
      {
        solutionTypes.map((type, i) => {
          return (
            <div key={i} className="solution-type">
              <div className="solution-type-icon">
                <img src={type.icon} />
              </div>
              <div className="solution-type-caption">
                <p className="caption">{type.caption}</p>
              </div>
            </div>
          );
        })
      }
    </StyledSolution>
  );
}

export default Solution;