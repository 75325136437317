import Brand from "./components/Brand";
import Solution from "./components/Solution";

const sections = [
  {
    title: 'Who We Are',
    href: 'who-we-are'
  },
  {
    title: 'What We Do',
    href: 'what-we-do'
  },
  {
    title: 'Contact',
    href: 'contact'
  }
];

const msg = `Innovative Digital Solutions That Accelerate Missions Into the 
  Future`;

const solutionCaptions = [
  'Agile Management',
  'Data Analytics',
  'CX/UX Design',
  'IT Modernization & Transformation',
  'Cybersecurity',
  'Digital Communications'
];

const content = [
  {
    subtitle: 'A joint venture built on trust and compatibility',
    desc: `Digital Next brings together two companies trusted by U.S. Government 
      clients for over 30 years to transform processes, systems, and 
      communications into cohesive platforms that adapt to mission needs. 
      Recognized as leaders in multiple IT categories by Gartner, Forrester, and 
      IDC MarketScape, with over $100M in Government Services business in 2022, 
      and reach back to over 20,000 people working in multiple industries, we 
      are an SBA approved 8(a) and HUBZone Mentor Protégé Joint Venture of 
      Softek Enterprises and Sapient Government Services.`,
    asset: <Brand />
  },
  {
    subtitle: 'Experience and Data-Driven Digital Business Transformation',
    desc: `The essence of Digital Next revolves around delivery technically 
      exception services and solutions to the U.S. Government. We specialize in 
      evolving business and digital platforms using SAFe, Agile, DevOps and 
      Cloud technologies to deliver working solutions for the most critical IT 
      challenges. Our delivery teams undertake this challenge through relentless 
      problem solving with deep domain and engineering expertise with an 
      objective to deliver a suite of scalable and secure solutions.`,
    asset: <Solution />
  },
  {
    form: {
      'Name': {
        type: 'text',
        placeholder: 'Your Name'
      },
      'Email': {
        type: 'email',
        placeholder: 'you@company.com'
      },
      'Message': {
        type: 'textarea',
        placeholder: 'How can we help you?'
      }
    }
  }
];

const foot = {
  about: {
    caption: 'Digital Next LLC',
    desc: `A Joint Venture between Softek Enterprises and Sapient Government 
      Services`
  },
  info: {
    caption: 'Company',
    list: [
      'www.digitalnextllc.com',
      'info@digitalnextllc.com',
      '1.301.245.1959',
      'UEI: RLTCUG1GLTZ4',
      'Cage Code: 9DUR3'
    ]
  }
};

export {
  sections,
  msg,
  solutionCaptions,
  content,
  foot
};