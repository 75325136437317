const startScroll = () => {
  const currentPath = window.location.href;
  const internalLink =  currentPath.slice(currentPath.indexOf('#'));

  let section;
  try {
    section = document.querySelector(internalLink);
  } catch (e) {
    console.log('Invalid Internal Link!', e.message);
    section = null;
  }

  if (section != null) {
    section.scrollIntoView({
      behavior: 'smooth'
    });
  }
};

const addScroll = () => {
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      // e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
  });
};

export {
  startScroll,
  addScroll
};