import PropTypes from "prop-types";
import styled from "styled-components";

const StyledContent = styled.section`
  display: flex;
  flex-direction: column;

  .txt { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
    
    h2 {
      width: 100%;
      @media (min-width: 1024px) { width: 75%; }
    }
  }

  .asset {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

function Content({ href, title, subtitle, desc, asset }) {

  return (
    <StyledContent>
      <h3 id={href} className="section-title">{title}</h3>
      <div className="inner">
        <div className="txt">
          <h2>{subtitle}</h2>
          <div className="desc">
            <p>{desc}</p>
          </div>
        </div>
        <div className="asset">
          { asset }
        </div>
      </div>
    </StyledContent>
  );
}

Content.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  desc: PropTypes.string,
  asset: PropTypes.element
};

export default Content;