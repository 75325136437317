import styled from "styled-components";

import { foot } from "../config";
import { digitalNextLogo } from "../images";

const StyledFoot = styled.footer`
  width: 100%;
  height: var(--foot-height);

  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) { flex-direction: row; }
  justify-content: center;
  @media (min-width: 1024px) { justify-content: space-between; }
  align-items: center;

  border-top: var(--foot-border-width) solid var(--grey);
  padding: 0 var(--side-space);
  @media (min-width: 768px) { padding: 0 var(--side-space-md); }
  @media (min-width: 1024px) { padding: 0 var(--side-space-lg); }

  .wrapper {
    width: 100%;
    @media (min-width: 1024px) { width: 50%; }

    display: flex;
    align-items: center;

    &.about { 
      justify-content: center;
      @media (min-width: 1024px) { justify-content: flex-start; }
    }

    &.info {
      justify-content: flex-end;
    }
  }

  .caption {
    font-size: var(--fs-md);
  }
`;

const StyledAbout = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px) { align-items: flex-start; }
  gap: var(--foot-about-gap);

  .logo {
    img {
      width: var(--foot-logo-width);
      height: var(--foot-logo-height);
    }
  }

  .txt {
    width: var(--foot-about-txt-width);
    max-width: 100%;

    display: flex;
    flex-direction: column;
    gap: var(--foot-about-txt-gap);

    text-align: center;
    @media (min-width: 1024px) { text-align: inherit; }
  }
`;

const StyledInfo = styled.div`
  display: none;
  @media (min-width: 1024px) { display: flex; }
  flex-direction: column;
  align-items: flex-start;
  gap: var(--foot-info-gap);

  ul {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-flow: column;
    justify-items: start;
    align-items: center;
    gap: var(--foot-info-list-gap);
  }
`;

function Foot() {
  return (
    <StyledFoot>
      <div className="wrapper about">
        <StyledAbout>
          <div className="logo">
            <img src={digitalNextLogo} />
          </div>
          <div className="txt">
            <p className="caption">{foot.about.caption}</p>
            <p className="desc">{foot.about.desc}</p>
          </div>
        </StyledAbout>
      </div>
      <div className="wrapper info">
        <StyledInfo>
          <div className="txt">
            <p className="caption">{foot.info.caption}</p>
          </div>
          <ul>
            { 
            /*
              foot.info.list.links.map((link, i) => {
                return (
                  <li key={i}>
                    <a href={link}>{link}</a>
                  </li>
                );
              })
            */ 
            }
            {
              foot.info.list.map((item, i) => {
                return (
                  <li key={i}>
                    <p>{item}</p>
                  </li>
                );
              })
            }
          </ul>
        </StyledInfo>
      </div>
    </StyledFoot>
  );
}

export default Foot;