import { useEffect } from "react";
import Layout from "./components/Layout";
import Banner from "./components/Banner";
import Content from "./components/Content";
import Contact from "./components/Contact";
import { startScroll, addScroll } from "./scripts/scroll";

import { sections } from "./config";
import { content } from "./config";

function App() {
  const [s1, s2, s3] = sections;
  const [c1, c2, c3] = content;

  useEffect(() => {
    startScroll();
    addScroll();
  }, []);

  return (
    <Layout>
      <Banner />
      <Content
        title={s1.title}
        href={s1.href}
        subtitle={c1.subtitle} 
        desc={c1.desc} 
        asset={c1.asset}
      />
      <Content
        title={s2.title}
        href={s2.href}
        subtitle={c2.subtitle} 
        desc={c2.desc} 
        asset={c2.asset}
      />
      <Contact
        href={s3.href}
        title={s3.title}
        formInfo={Object.entries(c3.form)}
      />
    </Layout>
  );
}

export default App;