import { createGlobalStyle } from "styled-components"; 
import variables from "./variables";

import RedHatDisplay from "../fonts/RedHatDisplay-VariableFW.ttf";

const GlobalStyle = createGlobalStyle`
  ${variables};

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    font-size: var(--fs-md);
    font-family: 'Red Hat Display';
    letter-spacing: -0.45px;
    
    margin: 0;
    overflow-x: hidden;
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;

    section {
      padding: var(--section-gap) var(--side-space);

      @media (min-width: 768px) { 
        padding: var(--section-gap-lg) var(--side-space-md);
      }
      @media (min-width: 1024px) {
        padding: var(--section-gap-lg) var(--side-space-lg);
      }
    }

    p {
      padding: var(--vert-space) 0;
    }
  }

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
  }

  a {
    display: inline-block;
    
    color: inherit;
    text-decoration: none;
  }

  p {
    margin: 0;

    &.caption {
      color: var(--dark-teal);
      font-size: var(--fs-sm);
      @media (min-width: 1224px) { font-size: var(--fs-md); }
      font-weight: var(--fw-bold);
      padding: 0;
    }
  }

  img {
    display: block;
  }

  h1 {
    font-size: var(--fs-lg);
    @media (min-width: 1024px) { font-size: var(--fs-xl); }
    margin: 0;
  }

  h2 {
    font-size: var(--fs-lg);
    font-weight: var(--fw-bold);
    margin: 0;
  }

  h3 {
    margin: 0;
  }

  .section-title {
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--light-teal);
    font-size: 24px;
    font-weight: var(--fw-bold);
    padding: 0.5rem;
  }

  @font-face {
    font-family: 'Red Hat Display';
    src: url(${RedHatDisplay}) format('ttf');
  }
`;

export default GlobalStyle;