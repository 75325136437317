import styled from "styled-components";

import { unsplash } from "../images";
import { msg } from "../config";

const StyledBanner = styled.section`
  position: relative;

  width: 100%;
  height: var(--banner-height);

  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 0;

  .txt {
    width: var(--banner-txt-width);
    max-width: 100%;

    color: white;
    margin-left: var(--side-space);
    @media (min-width: 768px) { margin-left: var(--side-space-md); }
    @media (min-width: 1024px) { margin-left: var(--side-space-lg); }
    
    z-index: 1;
  }
`;

const StyledGradient = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  background: var(--banner-bg-gradient);
  z-index: 1;
`;

const BannerImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  
  width: 80%;
  height: 100%;

  background-image: url(${props => props.url});
  background-size: cover;
`;

function Banner() {
  return (
    <StyledBanner>
      <StyledGradient />
      <BannerImage url={unsplash} />
      <div className="txt">
        <h1>{msg}</h1>
      </div>
    </StyledBanner>
  );
}

export default Banner;