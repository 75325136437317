import styled from "styled-components";

import { sections } from "../config";
import { digitalNextLogo } from "../images";

const StyledHead = styled.header`
  width: 100%;
  height: var(--nav-height);

  display: flex;
  justify-content: center; 
  align-items: center;

  padding: 0 var(--side-space);
  @media (min-width: 768px) { padding: 0 var(--side-space-md); }
  @media (min-width: 1024px) { padding: 0 var(--side-space-lg); }
`;

const StyledNav = styled.nav`
  width: 100%;

  display: flex;
  justify-content: center;
  @media (min-width: 768px) { justify-content: space-between; }
  align-items: center;

  .logo {
    img {
      width: var(--nav-logo-width);
      height: var(--nav-logo-height);
    }
  }

  ul {
    display: none;
    @media (min-width: 768px) { display: flex; }

    gap: var(--nav-item-gap);
    padding: 0;
    margin: 0;
    list-style: none;

    a {
      color: var(--teal);
      font-weight: var(--fw-bold);
      padding: 0.25rem;
    }
  }
`;

function Head() {

  return (
    <StyledHead>
      <StyledNav>
        <div className="logo">
          <img src={digitalNextLogo} />
        </div>
        <ul>
          {
            sections.map(({ title, href }, i) => {
              return (
                <li key={i}>
                  <a href={`#${href}`}>{title}</a>
                </li>
              );
            })
          }
        </ul>
      </StyledNav>
    </StyledHead>
  );
}

export default Head;